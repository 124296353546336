import React from 'react';
import get from 'lodash/get';
import ImageCard from './ImageCard';
import HTMLCard from './HTMLCard';
import CarouselComponent from './Carousel';
import ImageMessage from './ImageMessage';
import AudioMessage from './AudioMessage';
import DocumentMessage from './DocumentMessage';
import MarkdownIt from 'markdown-it';
import 'github-markdown-css/github-markdown.css';
import './MarkdownExtra.css';

const md = new MarkdownIt({
  html: true, // Allows HTML tags inside Markdown
  linkify: true, // Auto-link URLs
  typographer: true, // Smart quotes, dashes
});
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // Add target="_blank" and rel="noopener noreferrer" to all links
  tokens[idx].attrPush(['target', '_blank']);
  tokens[idx].attrPush(['rel', 'noopener noreferrer']);
  tokens[idx].attrPush(['class', 'LinkInsideMessage']);

  return self.renderToken(tokens, idx, options);
};

const RANDOM_BLOCK = 'RANDOM_MESSAGE_BLOCK';
const SIMPLE_TEXT_BLOCK = 'SIMPLE_TEXT_BLOCK';
const LINK_TEXT_BLOCK = 'LINK_TEXT_BLOCK';
const IMAGE_BLOCK = 'IMAGE_BLOCK';
const HTML_BLOCK = 'HTML_BLOCK';
const CAROUSEL_BLOCK = 'CAROUSEL_BLOCK';
const IMAGE_MESSAGE = 'IMAGE_MESSAGE';
const AUDIO_MESSAGE = 'AUDIO_MESSAGE';
const DOCUMENT_MESSAGE = 'DOCUMENT_MESSAGE';

const replacePhonesInText = (origText) => {
  const regexPhone = new RegExp(
    /[+(]?\d(?:[-()+\s.]*\d){8,14}(?![-()+\s.]*\d)|\d{3,4}\s?\.\s?\d{7,11}|\*\d{3,4}|\d{3,4}\*/gim
  );
  return origText.replace(regexPhone, (match, offset, fullText) => {
    const beforeMatch = fullText.slice(Math.max(0, offset - 3), offset); // Look before match
    const afterMatch = fullText.slice(
      offset + match.length,
      Math.max(offset + match.length + 7, fullText.length)
    ); // Look after match

    // [match](tel:...), ignore
    if (beforeMatch.includes('[') && afterMatch.includes('(tel:')) {
      return match;
    }
    // [...](tel:match), ignore
    const telPrefix = fullText.slice(Math.max(0, offset - 7), offset);
    if (telPrefix.includes(']') && telPrefix.includes('(tel:')) {
      return match;
    }

    return `[${match}](tel:${match})`;
  });
};
const MessageRenderer = ({ message, isProactiveChat, isFromUser }) => {
  try {
    const messageType = get(message, 'meta.message_block_type');
    const defaultText = message.content;
    let imageUrl, fileUrl;

    switch (messageType) {
      case LINK_TEXT_BLOCK:
        const linkText = get(message, 'meta.linkText');
        const linkURL = get(message, 'meta.url');
        const styles = { color: '#00a5ff' };
        return (
          <a href={linkURL} target="_blank" rel="noopener noreferrer" style={styles}>
            {linkText || linkURL}
          </a>
        );
      case IMAGE_BLOCK:
        const text = get(message, 'meta.text');
        const titleLabel = get(message, 'meta.titleLabel');
        const titleURL = get(message, 'meta.url');
        const image = get(message, 'meta.image');
        return <ImageCard text={text} titleLabel={titleLabel} titleURL={titleURL} image={image} />;

      case HTML_BLOCK:
        const htmlURL = get(message, 'meta.url');
        const html = get(message, 'meta.html');
        const width = get(message, 'meta.width');
        const height = get(message, 'meta.height');
        return (
          <HTMLCard
            html={html}
            url={htmlURL}
            width={width}
            height={height}
            isFromUser={isFromUser}
          />
        );

      case CAROUSEL_BLOCK:
        const carouselElements = get(message, 'meta.carousel_elements');
        return (
          <CarouselComponent
            carouselElements={carouselElements}
            isProactiveChat={isProactiveChat}
          />
        );

      case IMAGE_MESSAGE:
        imageUrl = get(message, 'meta.imageUrl');
        return <ImageMessage imageUrl={imageUrl} />;

      case AUDIO_MESSAGE:
        fileUrl = get(message, 'meta.fileUrl');
        return <AudioMessage fileUrl={fileUrl} />;

      case DOCUMENT_MESSAGE:
        fileUrl = get(message, 'meta.fileUrl');
        return <DocumentMessage fileUrl={fileUrl} />;

      case SIMPLE_TEXT_BLOCK:
      case RANDOM_BLOCK:
        const textValue = replacePhonesInText(get(message, 'meta.text'));
        return (
          <div
            className="markdown-body"
            dangerouslySetInnerHTML={{ __html: md.render(textValue) }}
          ></div>
        );

      default:
        return defaultText;
    }
  } catch (error) {
    // Sentry.captureException(error);
    console.log('MESSAGE RENDERER FAILED - ', error);
  }
};

export default MessageRenderer;
