import React from 'react';

import { connect } from 'react-redux';

import { selectConversationAllParticipants } from 'store/crm/selectors';

import './SenderName.scss';

const SenderName = (props) => {
  const { senderID, conversationParticipants, style, incoming } = props;

  const getSenderName = () => {
    const { current_participants, all_participants } = conversationParticipants;

    if (current_participants && all_participants) {
      const filterCurrentParticipants = current_participants.filter(
        (item) => item?.participant_id === senderID
      );
      const filterOldParticipants = all_participants.filter(
        (item) => item?.participant_id === senderID
      );

      let senderName = '';
      if (filterOldParticipants?.length > 0) {
        senderName = filterOldParticipants[0]?.name;
      } else if (filterCurrentParticipants?.length > 0) {
        senderName = filterCurrentParticipants[0]?.name;
      }

      return senderName ? senderName : 'Unknown user';
    }
  };
  let className = 'SenderName small-text';
  className += incoming ? ' SenderNameIncoming' : ' SenderNameOutgoing';

  return (
    <div className={className} style={style}>
      {getSenderName()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationParticipants: selectConversationAllParticipants(state),
});

export default connect(mapStateToProps, null)(SenderName);
