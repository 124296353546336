import React, { Component } from 'react';
import get from 'lodash/get';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import ObjectNameFromNode from 'pages/Flow/Services/ObjectNameFromNode';
import { AddButton, LinkButton } from 'components/common/Button/Buttons';
import ModalC from 'pages/Flow/components/ModalContainer';
import ContextMenu from 'components/ContextMenu';
import SeparatorV2 from 'components/common/SeparatorV2';
import LabeledCheckbox from 'components/common/LabeledCheckbox';
import languageMap from 'Language/Language';

const inputStyle = {
  color: 'black',
  border: '1px solid rgba(34,36,38,.15)',
  padding: '8px 20px 8px 20px',
  fontSize: 14,
  height: 37,
  borderRadius: 4,
  margin: '20px 30px 0px 0px',
};

class Connection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      varType: 'entity',
      showMenu: false,
    };

    this.entities = _.without(
      this.props.entities.map((row) => row.entity_name),
      ''
    );
    this.entities.unshift('');

    this.operators = {
      '': 'comparison operator',
      '=': 'equals to',
      '>': 'greater than',
      '>=': 'greater than or equal to',
      '<': 'less than',
      '<=': 'less than or equal to',
      '!=': 'not equal to',
      exists: 'exists',
      is_in: 'is in',
      contains: 'contains',
    };

    if (this.entities.indexOf(this.props.data.variable) === -1) {
      this.state.varType = 'context';
    }
  }

  onChange = (field, value) => {
    this.props.onChange(
      this.props.data.sourceNodeId,
      this.props.data.destinationNodeId,
      field,
      value
    );
  };

  handleVarTypeChange = (event) => {
    const updatedType = event.target.value;

    if (this.state.varType !== updatedType) {
      this.setState({ varType: updatedType });
      this.onChange('variable', '');
    }
  };

  handleVariableChange = (event, data) => {
    this.onChange('variable', data?.value || event.target.value);
  };

  handleOperatorChange = (event, { value }) => {
    this.onChange('operator', value);
  };

  handleValueChange = (event) => {
    this.onChange('value', event.target.value);
  };

  handleMenuShow = (event) => {
    this.setState({
      showMenu: true,
      mouseX: event.pageX - 50,
      mouseY: event.pageY,
    });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  handleMenuSelect = (change) => {
    this.setState({ showMenu: false });
    this.onChange(change);
  };

  render() {
    const containerStyle = {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '100%',
      padding: 0,
      margin: 0,
    };

    const headerStyle = {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 20,
    };

    const ifLabelStyle = {
      borderRadius: '19px',
      backgroundColor: '#3f3f3f',
      margin: '0px 20px 0px 0px',
      padding: '8px 13px',
      fontSize: 18,
      fontWeight: 'bold',
      color: 'white',
    };

    const varTypeStyle = {
      margin: '0px 20px 0px 0px',
      fontSize: 15,
      fontWeight: 'normal',
      color: '#333',
    };

    const fieldStyle = {
      margin: '20px 30px 0px 0px',
    };

    const rightHeaderStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    };

    const menuIconStyle = {
      fontSize: 30,
      marginLeft: 26,
      color: '#404040',
    };

    let leftHeader = null;
    let fields = null;
    let variableInput = null;
    let menu = null;
    let label = this.props.data.label;

    if (this.props.destinationNodeIsIntent) label = languageMap.onMatchIntent;

    let menuOptions = [
      {
        label: 'Move Up',
        returnValue: 'move_up',
      },
      {
        label: 'Move Down',
        returnValue: 'move_down',
      },
      {
        label: 'Delete',
        returnValue: 'delete_connection',
      },
    ];

    // Remove move_down option on else branch
    if (`${this.props.data.order_position}` === '0') {
      menuOptions.splice(1, 1);
    }

    // Remove move_down option on else branch
    if (this.props.index === 0) {
      menuOptions.splice(0, 1);
    }

    if (this.state.showMenu) {
      menu = (
        <ModalC onHide={this.hideMenu}>
          <ContextMenu
            left={this.state.mouseX}
            top={this.state.mouseY}
            options={menuOptions}
            onSelect={this.handleMenuSelect}
          />
        </ModalC>
      );
    }

    if (this.state.varType === 'entity') {
      variableInput = (
        <Dropdown
          selection
          style={fieldStyle}
          value={this.props.data.variable}
          onChange={this.handleVariableChange}
          options={this.entities.map((entity) => ({
            value: entity,
            text: entity === '' ? 'Select Variable' : entity,
          }))}
        />
      );
    } else if (this.state.varType === 'context') {
      variableInput = (
        <input
          style={inputStyle}
          type="text"
          value={this.props.data.variable}
          onChange={this.handleVariableChange}
        />
      );
    }

    if (this.props.destinationNodeIsIntent || this.props.data.order_position === 0) {
      leftHeader = <span style={ifLabelStyle}>{label}</span>;
    } else {
      leftHeader = (
        <>
          <span style={ifLabelStyle}>{label}</span>
          <FormControlLabel
            style={varTypeStyle}
            control={
              <Radio
                color="default"
                name={'varType' + this.props.data.destinationNodeId}
                value="entity"
                checked={this.state.varType === 'entity'}
                onChange={this.handleVarTypeChange}
              />
            }
            label={languageMap.entity}
          />

          <FormControlLabel
            label={languageMap.context}
            control={
              <Radio
                color="default"
                name={'varType' + this.props.data.destinationNodeId}
                value="context"
                checked={this.state.varType === 'context'}
                onChange={this.handleVarTypeChange}
              />
            }
            style={varTypeStyle}
          />
        </>
      );

      fields = (
        <>
          {variableInput}

          <Dropdown
            selection
            style={fieldStyle}
            value={this.props.data.operator}
            onChange={this.handleOperatorChange}
            options={Object.entries(this.operators).map(([op, text]) => ({
              value: op,
              text,
            }))}
          />

          <input
            style={inputStyle}
            type="text"
            value={this.props.data.value}
            onChange={this.handleValueChange}
          />
        </>
      );
    }

    const { objectIndex } = this.props.sourceNode;

    return (
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div>{leftHeader}</div>
          <div style={rightHeaderStyle}>
            <LinkButton text={this.props.nodeName} nodeIndex={objectIndex || null} />
            <span style={menuIconStyle} onClick={this.handleMenuShow} className="material-icons">
              more_vert
            </span>
          </div>
        </div>

        {fields}
        {menu}
      </div>
    );
  }
}

class Connections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddConnectionMenu: false,
      mouseX: 0,
      addMenuTop: 0,
      allowInterruptions: Boolean(this.props.nodes[this.props.sourceNodeId].allow_interruptions),
    };
  }

  handleChangeAllowInterruptions = () => {
    this.setState((prevState) => {
      this.props.onChangeAllowInterruptions(!prevState.allowInterruptions);
      return { allowInterruptions: !prevState.allowInterruptions };
    });
  };

  addConnection = (id) => {
    this.hideAddConnectionMenu();
    this.props.onChange(this.props.sourceNodeId, id, 'create_connection');
  };

  showAddConnectionMenu = (event) => {
    this.setState({
      showAddConnectionMenu: true,
      mouseX: event.pageX,
      mouseY: event.pageY,
    });
  };

  hideAddConnectionMenu = () => {
    this.setState({ showAddConnectionMenu: false });
  };

  render() {
    let addMenu;
    const node = this.props.nodes[this.props.sourceNodeId];
    const allowNewConnection = node.prev.length === 0 || node.object_class !== 'flow_connector';

    let containerStyle = {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
    };

    let buttonStyle = {
      // margin: '30px 0px 30px 30px',
    };

    if (this.state.showAddConnectionMenu) {
      const { nodes, connectionFilter, nodeNames } = this.props;

      let options = Object.entries(nodes)
        .filter(connectionFilter)
        .sort()
        .map(([id, node]) => {
          return {
            label: get(nodeNames, `${id}.nodeName`),
            returnValue: id,
            additionalLabel: node.objectIndex,
          };
        });

      if (_.isEmpty(options)) {
        alert('You have no valid nodes with which you can connect');
      } else {
        addMenu = (
          <ModalC onHide={this.hideAddConnectionMenu}>
            <ContextMenu
              left={this.state.mouseX}
              top={this.state.mouseY}
              options={options}
              onSelect={this.addConnection}
              hasSearch={true}
            />
          </ModalC>
        );
      }
    }

    return (
      <div style={containerStyle}>
        <LabeledCheckbox
          checked={this.state.allowInterruptions}
          onChange={this.handleChangeAllowInterruptions}
          label={languageMap.allowInterruptions}
          containerStyle={{ marginLeft: -4, marginBottom: 3 }}
        />

        {allowNewConnection && (
          <AddButton
            style={buttonStyle}
            text={languageMap.addNewConnection}
            icon="add"
            onClick={this.showAddConnectionMenu}
          />
        )}
        {this.props.connections &&
          this.props.connections.map((connectionData, index) => {
            const sourceNode = this.props.nodes[connectionData.destinationNodeId];
            return (
              <React.Fragment key={connectionData.connection_id}>
                <Connection
                  index={index}
                  destinationNodeIsIntent={get(sourceNode, 'object_class') === 'intent'}
                  entities={this.props.entities}
                  data={connectionData}
                  onChange={this.props.onChange}
                  nodeName={<ObjectNameFromNode node={sourceNode} />}
                  sourceNode={sourceNode}
                />
                {index === this.props.connections.length - 1 ? null : <SeparatorV2 />}
              </React.Fragment>
            );
          })}
        {addMenu}
      </div>
    );
  }
}

export default Connections;
