import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { parseMessage } from 'utils/crm';

import ConversationsStreamHeader from './components/ConversationsStreamHeader';
import ConversationConsole from './components/ConversationConsole';
import ConversationStream from './components/ConversationsStream';
import ScrollToBottomButton from './components/ScrollToBottomButton';

import { setMacros } from 'store/crm/actions';
import {
  selectConversationHistory,
  selectCurrentConversation,
  selectCurrentUser,
  selectInboxFolder,
  selectIsMobile,
  selectMacros,
} from 'store/crm/selectors';

import { getToken } from 'store/auth/selectors';

import './ConversationsStreamContainer.scss';

const ConversationsStreamContainer = (props) => {
  const {
    assignedConversation,
    conversationHistory,
    currentConversation,
    currentConversation: { assignee, conversation_id, language },
    currentUser,
    macros,
    setMacros,
    token,
    inboxFolder: { submenu },
    handleConversationDetailsButtonClick,
    conversationDetails,
  } = props;

  const messagesElement = useRef();

  const [messages, setMessages] = useState([]);
  const [showScrollDownButton] = useState(false);
  const [previousConversationId, setPreviousConversationId] = useState(null);

  const fetchMessages = useCallback(() => {
    const newMessages = conversationHistory
      .map(parseMessage)
      .sort((a, b) => a.timestamp - b.timestamp);

    return newMessages;
  }, [conversationHistory]);

  // This doesn't work but we'll keep the code for now in case we need scrollDown button in the future
  // The auto-scrolling is handled by ConversationsStream.js
  const scrollDown = useCallback(() => {
    const lastMessageElement = messagesElement.current?.lastElementChild;
    if (lastMessageElement) {
      lastMessageElement.scrollIntoView();
      if (showScrollDownButton) {
        // setShowScrollDownButton(false);
      }
    }
  }, [showScrollDownButton]);

  useEffect(() => {
    if (previousConversationId !== conversation_id) {
      scrollDown();
    }
    if (typeof conversation_id === 'number') {
      setPreviousConversationId(conversation_id);
    }
  }, [conversation_id, scrollDown, previousConversationId]);

  useEffect(() => {
    if (!conversation_id || !conversationHistory) {
      return;
    }
    let newMessages;
    try {
      newMessages = fetchMessages();
    } catch (e) {
      alert('Err fetch messages - ', e);
    }
    if (newMessages) {
      setMessages(newMessages);
      scrollDown();
    }
  }, [fetchMessages, conversationHistory, conversation_id, assignedConversation, setMessages]);

  const handleScrollMessages = () => {
    // const { scrollTop, scrollHeight, clientHeight } = messagesElement.current?.parentElement;
    // const scrollFromBottom = scrollHeight - clientHeight - scrollTop;
    // const maxScrollFromBottom = percentOfScreenScrollOffset * clientHeight;
    // if (scrollFromBottom < maxScrollFromBottom && showScrollDownButton) {
    //   setShowScrollDownButton(false);
    // }
    // if (scrollFromBottom > maxScrollFromBottom && !showScrollDownButton) {
    //   setShowScrollDownButton(true);
    // }
  };

  const isAssignedToCurrentUser = assignee === currentUser.user_id;
  const isExternalFolder = submenu === 'External';

  return (
    <div id="conversation-stream-container" className="ConversationStreamContainer">
      <ConversationsStreamHeader
        conversationDetails={conversationDetails}
        handleConversationDetailsButtonClick={handleConversationDetailsButtonClick}
      />
      <ConversationStream
        language={language}
        messages={messages}
        messagesElement={messagesElement}
        onScrollMessages={handleScrollMessages}
        currentUser={currentUser}
      />
      <ScrollToBottomButton
        showScrollDownButton={showScrollDownButton}
        onButtonClick={scrollDown}
      />
      <ConversationConsole
        token={token}
        currentConversation={currentConversation}
        isAssignedToCurrentUser={isAssignedToCurrentUser}
        disabled={isExternalFolder && !isAssignedToCurrentUser}
        macros={macros}
        setMacros={setMacros}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationHistory: selectConversationHistory(state),
  currentConversation: selectCurrentConversation(state),
  currentUser: selectCurrentUser(state),
  macros: selectMacros(state),
  token: getToken(state),
  inboxFolder: selectInboxFolder(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setMacros,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsStreamContainer);
